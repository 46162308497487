import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

const Icon = ({
  icon,
  width = '15',
  height = '15',
  color = 'blue',
  indent = true
}) => {
  const iconClasses = classNames('svg-icon', `svg-icon--${color}`, {
    'svg-icon--indent': indent
  });

  return (
    <svg
      viewBox={icon.viewBox}
      width={width}
      height={height}
      className={iconClasses}
    >
      <use xlinkHref={`#${icon.id}`} />
    </svg>
  );
};

Icon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  indent: PropTypes.bool
};

export default Icon;
